<template>
	<div class="login-body flex flex-column align-items-center justify-content-center">
    <div class="login-panel p-5 pt-6 p-fluid flex flex-column justify-content-center">
        <h4 class="font-bold">Momentum Campaigns Button Generator<br /><small class="font-normal text-sm">Powered by <a href="https://www.optakit.com" target="_new">Optakit</a></small></h4>
        <span class="mb-4 line-height-3">Sign in to access</span>

        <!-- <span class="p-input-icon-left mb-3">
            <i class="pi pi-user"></i>
            <InputText v-model="email" type="text" placeholder="Email Address" />
        </span>

        <span class="p-input-icon-left mb-6">
            <i class="pi pi-key"></i>
            <InputText v-model="password" type="password" placeholder="Password" />
        </span> -->

        <span v-if="error" class="mb-1 text-red-500 text-center">
            <i class="pi pi-exclamation-triangle"></i> {{ error }}
        </span>

        <!-- <Button type="button" label="Sign In with Email" @click="userSignIn"></Button>
        <span class="mt-2 text-center text-sm">or</span> -->
        <Button type="button" label="Sign In with Google" class="mt-1" @click="userSignInGoogle"></Button>
    </div>

    <!-- <span class="mt-4">Don’t you have an account? <span class="font-bold cursor-pointer" @click="showSignUp">Sign Up</span></span> -->
</div>

</template>

<script>
import { fireauth } from "@/firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider
} from "firebase/auth";


	export default {
		data() {
			return {
				checked: false,
                email: "",
                password: "",
                error: null,
			}
		},
		methods: {
			goDashboard(){
				this.$router.push({ path: '/' });
			},
            showSignUp(){
				this.$router.push({ path: '/' });
			},
            
    userSignIn() {
      signInWithEmailAndPassword(fireauth, this.email, this.password)
        .then(() => {
          this.goDashboard();
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
        });
    },
    userSignInGoogle() {
        const provider = new GoogleAuthProvider();
signInWithPopup(fireauth, provider)
  .then(() => {
    this.goDashboard();
  }).catch((error) => {
    console.log(error);
    this.error = error;
  });
    }
		}
	}
</script>

<style scoped>

</style>
